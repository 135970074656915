require("@rails/ujs").start() // activate ajax rails interactions
// require("@rails/activestorage").start()
// require("channels")
require("jquery")
require("bootstrap")
require('@fortawesome/fontawesome-free')

import 'css/application'
import 'bootstrap'
import 'offres_stage'

const images = require.context('../images', true)
