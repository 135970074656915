// affiche le formulaire de la composition de l’équipe si stage de M2
window.showDiv = function (divId, element) {
    document.getElementById(divId).style.display = element.selectedOptions[0].value === 'M2' ? 'block' : 'none'
    checkDuration()
}

// si stage de M2 alors la durée minimale ne peut pas être en dessous de 6 mois
// ne peut être activé que par showDiv()
window.checkDuration = function () {
    let level = document.getElementById("offres_stage_niveau").selectedOptions[0].value
    let duration = document.getElementById("offres_stage_duree")

    if (level === 'M2') {
        duration.value = 24
        duration.setAttribute("min", 24)
    } else {
        duration.value = 8
        duration.setAttribute("min", 2)
    }
}

// affiche les couleurs en fonction longueur minimale et maximale pour la description du projet de stage
// affichage pour les formulaires pour la modification
window.checkForm = function() {
    if (document.getElementById("offres_stage_niveau")) {
        let level = document.getElementById("offres_stage_niveau").selectedOptions[0].value
        document.getElementById("tocomplete").style.display = level === 'M2' ? 'block' : 'none'
    }

    if (document.getElementById("offres_stage_description_projet")) {
        let nb = document.getElementById("offres_stage_description_projet").value.length
        document.getElementById("counter").innerHTML = nb
        if (nb < 500 || nb > 5000) {
            document.getElementById("counter").parentElement.classList.replace("text-muted", "text-danger");
            document.getElementById("counter").parentElement.classList.replace("text-success", "text-danger");
        }
        if (nb >= 500 && nb <= 5000) {
            document.getElementById("counter").parentElement.classList.replace("text-muted", "text-success");
            document.getElementById("counter").parentElement.classList.replace("text-danger", "text-success");
        }
    }
}

// vérifie la longueur minimale et maximale pour la description du projet de stage pendant la saisie du formulaire
window.countChar = function (span, textarea) {
    let nb = textarea.value.length
    let counter = document.getElementById(span)
    counter.innerHTML = nb
    if (nb < 500 || nb > 5000) {
        counter.parentElement.classList.replace("text-muted","text-danger")
        counter.parentElement.classList.replace("text-success","text-danger")
    }
    if (nb >= 500 && nb <= 5000) {
        counter.parentElement.classList.replace("text-muted", "text-success")
        counter.parentElement.classList.replace("text-danger", "text-success")
    }
}
